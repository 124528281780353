import React, { Component } from "react";
import "../styles/home.css";
import "../styles/newhomepage.css";
import axios from "axios";
import { Link } from "react-router-dom"; // Assuming you use React Router for navigation
import MetaGlobal from "../constants/meta_global.ts";
import { Button, Card, Collapse } from "react-bootstrap"; // Import Button and Card from react-bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

class Home extends Component {
  // Initialize state
  state = {
    groupList: [],
    allTools: [],
    openIndex: null, // Track which panel is open
  };

  // Fetch data after the component mounts
  componentDidMount() {
    const siteTitle = "Home";
    document.title = `${siteTitle} - ${MetaGlobal.WebsiteName}`;

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/Home`)
      .then((response) => {
        // Destructure the response data
        const { groupList, allTools } = response.data;

        // Update the state with the fetched data
        this.setState({ groupList, allTools });
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }

  // Toggle the open panel by index
  togglePanel = (index) => {
    this.setState((prevState) => ({
      openIndex: prevState.openIndex === index ? null : index, // Close the panel if the same one is clicked
    }));
  };

  render() {
    const { groupList, allTools, openIndex } = this.state; // Destructure openIndex from state

    return (
      <div className="text-center">
        <h1 className="display-6 fw-bold">Welcome</h1>
        <p>One place for all your online tools</p>

        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-12 tln noP clear" id="domainTools">
              {groupList.map((group, index) => (
                <div key={index}>
                  <h3
                    className="hh hc1 clickable" // Add a class for cursor styling
                    onClick={() => this.togglePanel(index)} // Toggle panel on click
                  >
                    <i className="fa fa-file-text"></i>
                    {group.groupName} {/* Display the group name */}
                  </h3>

                  <Card id="collapsible-panel-example-1">
                    <Collapse in={openIndex === index}>
                      <Card.Body>
                        <ul>
                          {allTools
                            .filter(
                              (tool) => tool.toolGroupID === group.groupID
                            )
                            .map((tool, toolIndex) => (
                              <li key={toolIndex}>
                                <Link to={`/${tool.toolSlug}`}>
                                  {tool.toolName}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </Card.Body>
                    </Collapse>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
