export const WordCounter: string = "word-counter";
export const Md5HashGenerator: string = "md5-hash-generator";
export const LoremIpsumGenerator = "lorem-ipsum-generator";

export const CmToMileConverter = "cm-to-mile-converter";
export const MileToCmConverter = "mile-to-cm-converter";
export const CmToMeterConverter = "cm-to-meter-converter";
export const MeterToCmConverter = "meter-to-cm-converter";
export const CmToFeetConverter = "cm-to-feet-converter";
export const FeetToCmConverter = "feet-to-cm-converter";
export const CmToInchConverter = "cm-to-inch-converter";
export const InchToCmConverter = "inch-to-cm-converter";
export const CmToKmConverter = "cm-to-km-converter";
export const KmToCmConverter = "km-to-cm-converter";
export const CmToMmConverter = "cm-to-mm-converter";
export const MmToCmConverter = "mm-to-cm-converter";
export const CmToYardConverter = "cm-to-yard-converter";
export const YardToCmConverter = "yard-to-cm-converter";

export const MmToMeterConverter = "mm-to-meter-converter";
export const MmToKmConverter = "mm-to-km-converter";
export const MmToInchConverter = "mm-to-inch-converter";
export const MmToFeetConverter = "mm-to-feet-converter";
export const MmToYardConverter = "mm-to-yard-converter";
export const MmToMileConverter = "mm-to-mile-converter";

export const MeterToMmConverter = "meter-to-mm-converter";
export const MeterToKmConverter = "meter-to-km-converter";
export const MeterToInchConverter = "meter-to-inch-converter";
export const MeterToFeetConverter = "meter-to-feet-converter";
export const MeterToYardConverter = "meter-to-yard-converter";
export const MeterToMileConverter = "meter-to-mile-converter";

export const KmToMmConverter = "km-to-mm-converter";
export const KmToMeterConverter = "km-to-meter-converter";
export const KmToInchConverter = "km-to-inch-converter";
export const KmToFeetConverter = "km-to-feet-converter";
export const KmToYardConverter = "km-to-yard-converter";
export const KmToMileConverter = "km-to-mile-converter";

export const InchToMmConverter = "inch-to-mm-converter";
export const InchToMeterConverter = "inch-to-meter-converter";
export const InchToKmConverter = "inch-to-km-converter";
export const InchToFeetConverter = "inch-to-feet-converter";
export const InchToYardConverter = "inch-to-yard-converter";
export const InchToMileConverter = "inch-to-mile-converter";

export const FeetToMmConverter = "feet-to-mm-converter";
export const FeetToMeterConverter = "feet-to-meter-converter";
export const FeetToKmConverter = "feet-to-km-converter";
export const FeetToInchConverter = "feet-to-inch-converter";
export const FeetToYardConverter = "feet-to-yard-converter";
export const FeetToMileConverter = "feet-to-mile-converter";

export const YardToMmConverter = "yard-to-mm-converter";
export const YardToMeterConverter = "yard-to-meter-converter";
export const YardToKmConverter = "yard-to-km-converter";
export const YardToInchConverter = "yard-to-inch-converter";
export const YardToFeetConverter = "yard-to-feet-converter";
export const YardToMileConverter = "yard-to-mile-converter";

export const MileToMmConverter = "mile-to-mm-converter";
export const MileToMeterConverter = "mile-to-meter-converter";
export const MileToKmConverter = "mile-to-km-converter";
export const MileToInchConverter = "mile-to-inch-converter";
export const MileToFeetLengthConverter = "mile-to-feet-length-converter";
export const MileToYardLengthConverter = "mile-to-yard-length-converter";

export const MicrogramToMilligramConverter = "mcg-to-mg-converter";
export const MicrogramToGramConverter = "mcg-to-gram-converter";
export const MicrogramToKilogramConverter = "mcg-to-kg-converter";
export const MicrogramToMetricTonConverter = "mcg-to-ton-converter";
export const MicrogramToOunceConverter = "mcg-to-ounce-converter";
export const MicrogramToPoundConverter = "mcg-to-pound-converter";
export const MicrogramToStoneConverter = "mcg-to-stone-converter";

export const MilligramToMicrogramConverter = "mg-to-mcg-converter";
export const MilligramToGramConverter = "mg-to-gram-converter";
export const MilligramToKilogramConverter = "mg-to-kg-converter";
export const MilligramToMetricTonConverter = "mg-to-ton-converter";
export const MilligramToOunceConverter = "mg-to-ounce-converter";
export const MilligramToPoundConverter = "mg-to-pound-converter";
export const MilligramToStoneConverter = "mg-to-stone-converter";

export const GramToMicrogramConverter = "gram-to-mcg-converter";
export const GramToMilligramConverter = "gram-to-mg-converter";
export const GramToKilogramConverter = "gram-to-kg-converter";
export const GramToMetricTonConverter = "gram-to-ton-converter";
export const GramToOunceConverter = "gram-to-ounce-converter";
export const GramToPoundConverter = "gram-to-pound-converter";
export const GramToStoneConverter = "gram-to-stone-converter";

export const KilogramToMicrogramConverter = "kg-to-mcg-converter";
export const KilogramToMilligramConverter = "kg-to-mg-converter";
export const KilogramToGramConverter = "kg-to-gram-converter";
export const KilogramToMetricTonConverter = "kg-to-ton-converter";
export const KilogramToOunceConverter = "kg-to-ounce-converter";
export const KilogramToPoundConverter = "kg-to-pound-converter";
export const KilogramToStoneConverter = "kg-to-stone-converter";

export const MetricTonToMicrogramConverter = "ton-to-mcg-converter";
export const MetricTonToMilligramConverter = "ton-to-mg-converter";
export const MetricTonToGramConverter = "ton-to-gram-converter";
export const MetricTonToKilogramConverter = "ton-to-kg-converter";
export const MetricTonToOunceConverter = "ton-to-ounce-converter";
export const MetricTonToPoundConverter = "ton-to-pound-converter";
export const MetricTonToStoneConverter = "ton-to-stone-converter";

export const OunceToMicrogramConverter = "ounce-to-mcg-converter";
export const OunceToMilligramConverter = "ounce-to-mg-converter";
export const OunceToGramConverter = "ounce-to-gram-converter";
export const OunceToKilogramConverter = "ounce-to-kg-converter";
export const OunceToMetricTonConverter = "ounce-to-ton-converter";
export const OunceToPoundConverter = "ounce-to-pound-converter";
export const OunceToStoneConverter = "ounce-to-stone-converter";

export const PoundToMicrogramConverter = "pound-to-mcg-converter";
export const PoundToMilligramConverter = "pound-to-mg-converter";
export const PoundToGramConverter = "pound-to-gram-converter";
export const PoundToKilogramConverter = "pound-to-kg-converter";
export const PoundToMetricTonConverter = "pound-to-ton-converter";
export const PoundToOunceConverter = "pound-to-ounce-converter";
export const PoundToStoneConverter = "pound-to-stone-converter";

export const StoneToMicrogramConverter = "stone-to-mcg-converter";
export const StoneToMilligramConverter = "stone-to-mg-converter";
export const StoneToGramConverter = "stone-to-gram-converter";
export const StoneToKilogramConverter = "stone-to-kg-converter";
export const StoneToMetricTonConverter = "stone-to-ton-converter";
export const StoneToOunceConverter = "stone-to-ounce-converter";
export const StoneToPoundConverter = "stone-to-pound-converter";

//Temperatures
export const FahrenheitToCelsiusConverter = "fahrenheit-to-celsius-converter";
export const FahrenheitToKelvinConverter = "fahrenheit-to-kelvin-converter";
export const FahrenheitToRankineConverter = "fahrenheit-to-rankine-converter";
export const CelsiusToFahrenheitConverter = "celsius-to-fahrenheit-converter";
export const CelsiusToKelvinConverter = "celsius-to-kelvin-converter";
export const CelsiusToRankineConverter = "celsius-to-rankine-converter";
export const KelvinToFahrenheitConverter = "kelvin-to-fahrenheit-converter";
export const KelvinToCelsiusConverter = "kelvin-to-celsius-converter";
export const KelvinToRankineConverter = "kelvin-to-rankine-converter";
export const RankineToFahrenheitConverter = "rankine-to-fahrenheit-converter";
export const RankineToCelsiusConverter = "rankine-to-celsius-converter";
export const RankineToKelvinConverter = "rankine-to-kelvin-converter";

// Export all constants as a single object
const MetaSlugs = {
  FahrenheitToCelsiusConverter,
  FahrenheitToKelvinConverter,
  FahrenheitToRankineConverter,
  CelsiusToFahrenheitConverter,
  CelsiusToKelvinConverter,
  CelsiusToRankineConverter,
  KelvinToFahrenheitConverter,
  KelvinToCelsiusConverter,
  KelvinToRankineConverter,
  RankineToFahrenheitConverter,
  RankineToCelsiusConverter,
  RankineToKelvinConverter,

  WordCounter,
  Md5HashGenerator,
  LoremIpsumGenerator,

  CmToMileConverter,
  MileToCmConverter,
  CmToMeterConverter,
  MeterToCmConverter,
  CmToFeetConverter,
  FeetToCmConverter,
  CmToInchConverter,
  InchToCmConverter,
  CmToKmConverter,
  KmToCmConverter,
  CmToMmConverter,
  MmToCmConverter,
  CmToYardConverter,
  YardToCmConverter,

  MmToMeterConverter,
  MmToKmConverter,
  MmToInchConverter,
  MmToFeetConverter,
  MmToYardConverter,
  MmToMileConverter,

  MeterToMmConverter,
  MeterToKmConverter,
  MeterToInchConverter,
  MeterToFeetConverter,
  MeterToYardConverter,
  MeterToMileConverter,

  KmToMmConverter,
  KmToMeterConverter,
  KmToInchConverter,
  KmToFeetConverter,
  KmToYardConverter,
  KmToMileConverter,

  InchToMmConverter,
  InchToMeterConverter,
  InchToKmConverter,
  InchToFeetConverter,
  InchToYardConverter,
  InchToMileConverter,

  FeetToMmConverter,
  FeetToMeterConverter,
  FeetToKmConverter,
  FeetToInchConverter,
  FeetToYardConverter,
  FeetToMileConverter,

  YardToMmConverter,
  YardToMeterConverter,
  YardToKmConverter,
  YardToInchConverter,
  YardToFeetConverter,
  YardToMileConverter,

  MileToMmConverter,
  MileToMeterConverter,
  MileToKmConverter,
  MileToInchConverter,
  MileToFeetLengthConverter,
  MileToYardLengthConverter,

  MicrogramToMilligramConverter,
  MicrogramToGramConverter,
  MicrogramToKilogramConverter,
  MicrogramToMetricTonConverter,
  MicrogramToOunceConverter,
  MicrogramToPoundConverter,
  MicrogramToStoneConverter,

  MilligramToMicrogramConverter,
  MilligramToGramConverter,
  MilligramToKilogramConverter,
  MilligramToMetricTonConverter,
  MilligramToOunceConverter,
  MilligramToPoundConverter,
  MilligramToStoneConverter,

  GramToMicrogramConverter,
  GramToMilligramConverter,
  GramToKilogramConverter,
  GramToMetricTonConverter,
  GramToOunceConverter,
  GramToPoundConverter,
  GramToStoneConverter,

  KilogramToMicrogramConverter,
  KilogramToMilligramConverter,
  KilogramToGramConverter,
  KilogramToMetricTonConverter,
  KilogramToOunceConverter,
  KilogramToPoundConverter,
  KilogramToStoneConverter,

  MetricTonToMicrogramConverter,
  MetricTonToMilligramConverter,
  MetricTonToGramConverter,
  MetricTonToKilogramConverter,
  MetricTonToOunceConverter,
  MetricTonToPoundConverter,
  MetricTonToStoneConverter,

  OunceToMicrogramConverter,
  OunceToMilligramConverter,
  OunceToGramConverter,
  OunceToKilogramConverter,
  OunceToMetricTonConverter,
  OunceToPoundConverter,
  OunceToStoneConverter,

  PoundToMicrogramConverter,
  PoundToMilligramConverter,
  PoundToGramConverter,
  PoundToKilogramConverter,
  PoundToMetricTonConverter,
  PoundToOunceConverter,
  PoundToStoneConverter,

  StoneToMicrogramConverter,
  StoneToMilligramConverter,
  StoneToGramConverter,
  StoneToKilogramConverter,
  StoneToMetricTonConverter,
  StoneToOunceConverter,
  StoneToPoundConverter,
};

export default MetaSlugs;
