import React, { Component, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../styles/table.css"; // Assuming you have the CSS file
import MetaGlobal from "../constants/meta_global.ts";

const LoremIpsumGenerator = () => {
  const { converterSlug } = useParams(); // Capture slug from URL
  const [toolData, setToolData] = useState([]);
  const [conversionResult, setConversionResult] = useState(null);
  const [loremType, setLoremType] = useState("3"); // Default value is "3"
  const [occurrences, setOccurrences] = useState(1); // Default occurrences

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/LoremIpsumGenerator/${converterSlug}`
      )
      .then((response) => {
        const data = response.data; // assume data is an array of groups
        setToolData(data); // Update the state with the fetched data

        const siteTitle = data.toolName;
        document.title = `${siteTitle} - ${MetaGlobal.WebsiteName}`;
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [converterSlug]); // Fetch data when the converterSlug changes

  const postData = (e) => {
    e.preventDefault(); // Prevent the default form submission
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/LoremIpsumGenerator/${converterSlug}`,
        { occurrences: occurrences, loremType: loremType }, // Send the value directly
        { headers: { "Content-Type": "application/json" } } // Set headers correctly
      )
      .then((response) => {
        setConversionResult(response.data); // Set conversion result
      })
      .catch((error) => {
        console.error("There was an error converting the value!", error);
      });
  };

  return (
    <div>
      <br />
      <div className="dashed-border">
        <h1 className="text-center fw-bold tool_title">{toolData.toolName}</h1>

        <p>Provide the value/text below</p>
        <form onSubmit={postData}>
          <div className="card">
            <h7>Select generate type:</h7>
            <div className="row">
              <div className="col-md-4">
                <label>
                  <input
                    type="radio"
                    value="3"
                    checked={loremType === "3"}
                    onChange={(e) => setLoremType(e.target.value)}
                  />
                  <span>Paragraphs</span>
                </label>
              </div>
              <div className="col-md-4">
                <label>
                  <input
                    type="radio"
                    value="2"
                    checked={loremType === "2"}
                    onChange={(e) => setLoremType(e.target.value)}
                  />
                  <span>Words</span>
                </label>
              </div>
              <div className="col-md-4">
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={loremType === "1"}
                    onChange={(e) => setLoremType(e.target.value)}
                  />
                  <span>Letters</span>
                </label>
              </div>
            </div>
          </div>
          <br />
          <h7>No of occurances</h7>
          <input
            type="number"
            step="any"
            value={occurrences}
            onChange={(e) => setOccurrences(e.target.value)} // Update input value
            required // Optional: makes the input required
            className="form-control"
            style={{ width: "150px" }}
          />
          <br />
          <br />
          <button type="submit" className="btn btn-primary">
            Generate
          </button>
        </form>
        <br />
        {conversionResult && (
          <div>
            <p
              dangerouslySetInnerHTML={{ __html: conversionResult.outPutText }}
            ></p>
          </div>
        )}
      </div>
      <br />
      <div class="">
        <p dangerouslySetInnerHTML={{ __html: toolData.unitDescription }}></p>
      </div>
    </div>
  );
};

export default LoremIpsumGenerator;
