import React, { Component, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../styles/table.css"; // Assuming you have the CSS file
import MetaGlobal from "../constants/meta_global.ts";

const LengthConverter = () => {
  const { converterSlug } = useParams(); // Capture slug from URL
  const [toolData, setToolData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [conversionResult, setConversionResult] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/LengthConverter/${converterSlug}`
      )
      .then((response) => {
        const data = response.data; // assume data is an array of groups
        setToolData(data); // Update the state with the fetched data

        const siteTitle = data.toolName;
        document.title = `${siteTitle} - ${MetaGlobal.WebsiteName}`;
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [converterSlug]); // Fetch data when the converterSlug changes

  const postData = (e) => {
    e.preventDefault(); // Prevent the default form submission
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/LengthConverter/${converterSlug}`,
        inputValue, // Send the value directly
        { headers: { "Content-Type": "application/json" } } // Ensure correct content type
      )
      .then((response) => {
        setConversionResult(response.data); // Set conversion result
      })
      .catch((error) => {
        console.error("There was an error converting the value!", error);
      });
  };

  const clearInput = () => {
    setInputValue(""); // Clear the input field
    setConversionResult(null); // Optionally clear the conversion result
  };

  return (
    <div>
      <br />
      <div className="dashed-border">
        <h1 className="text-center fw-bold tool_title">{toolData.toolName}</h1>

        <p>Provide the value below in {toolData.inputUnit}:</p>
        <form onSubmit={postData}>
          <input
            type="number"
            step="any"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)} // Update input value
            required // Optional: makes the input required
          />
          <br />
          <br />
          <button type="submit" className="btn btn-primary btn-space">
            Convert to {toolData.outputUnit}
          </button>

          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={clearInput} // Add onClick handler to clear the input
          >
            Clear
          </button>
        </form>
        <br />
        {conversionResult && (
          <div>
            <p>
              {conversionResult.outPutText} {toolData.outputUnit}
            </p>
          </div>
        )}
      </div>
      <br />
      <div class="">
        <p dangerouslySetInnerHTML={{ __html: toolData.unitDescription }}></p>
      </div>
    </div>
  );
};

export default LengthConverter;
