import logo from "./logo.svg";
import "./App.css";
import Layout from "./components/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import ConverterRouter from "./components/ConverterRouter.jsx"; // New router component

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home></Home>} />
          <Route path="/home" element={<Home />} />
          <Route path="/:converterSlug" element={<ConverterRouter />} />{" "}
          {/* Single dynamic route */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
