import React, { Component, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../styles/table.css"; // Assuming you have the CSS file
import "../styles/word-counter.css";
import MetaGlobal from "../constants/meta_global.ts";

const WordCounter = () => {
  const { converterSlug } = useParams(); // Capture slug from URL
  const [toolData, setToolData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [conversionResult, setConversionResult] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/WordCounter/${converterSlug}`
      )
      .then((response) => {
        const data = response.data; // assume data is an array of groups
        setToolData(data); // Update the state with the fetched data

        const siteTitle = data.toolName;
        document.title = `${siteTitle} - ${MetaGlobal.WebsiteName}`;
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [converterSlug]); // Fetch data when the converterSlug changes

  const postData = (e) => {
    e.preventDefault(); // Prevent the default form submission
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/WordCounter/${converterSlug}`,
        JSON.stringify(inputValue), // Send the value directly
        { headers: { "Content-Type": "application/json" } } // Ensure correct content type
      )
      .then((response) => {
        setConversionResult(response.data); // Set conversion result
      })
      .catch((error) => {
        console.error("There was an error converting the value!", error);
      });
  };

  const clearInput = () => {
    setInputValue(""); // Clear the input field
    setConversionResult(null); // Optionally clear the conversion result
  };

  return (
    <div>
      <br />
      <div className="dashed-border">
        <h1 className="text-center fw-bold tool_title">{toolData.toolName}</h1>

        <p>Please type or paste your content into the below textbox</p>
        <form onSubmit={postData}>
          <textarea
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)} // Update input value
            required // Optional: makes the input required
            rows={5}
            className="form-control"
            style={{ width: "100%" }}
          />
          <br />
          <br />
          <button type="submit" className="btn btn-primary btn-space">
            Show the Count
          </button>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={clearInput} // Add onClick handler to clear the input
          >
            Clear
          </button>
        </form>
        <br />
        {conversionResult && (
          <>
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <div className="card-white">
                  <div className="card-value">{conversionResult.noOfWords}</div>
                  <div className="card-name">Words</div>
                </div>
              </div>

              <div className="col-md-3 col-sm-12">
                <div className="card-white">
                  <div className="card-value">
                    {conversionResult.noOfSyllables}
                  </div>
                  <div className="card-name">Syllables</div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="card-white">
                  <div className="card-value">
                    {conversionResult.noOfSentences}
                  </div>
                  <div className="card-name">Sentences</div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="card-white">
                  <div className="card-value">
                    {conversionResult.readTimeInMinutes}
                  </div>
                  <div className="card-name">Read Time</div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <div className="card-white">
                  <div className="card-value">
                    {conversionResult.noOfCharactersWithSpace}
                  </div>
                  <div className="card-name">
                    Characters <br />
                    (With Free Space)
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-white">
                  <div className="card-value">
                    {conversionResult.noOfCharactersWithOutSpace}
                  </div>
                  <div className="card-name">
                    Characters <br />
                    (Without Free Space)
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <br />
      <div className="">
        <p dangerouslySetInnerHTML={{ __html: toolData.unitDescription }}></p>
      </div>
    </div>
  );
};

export default WordCounter;
