import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../styles/site.css";

class Layout extends Component {
  render() {
    const { children } = this.props;

    return (
      <div>
        <header>
          <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img
                  src="/images/onlinetoolspot-logo.png"
                  alt="OnlineToolSpot Logo"
                  width="100px"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="navbar-collapse collapse d-sm-inline-flex justify-content-between">
                <ul className="navbar-nav flex-grow-1">
                  <li className="nav-item">
                    <Link className="nav-link text-dark" to="/Home">
                      Home
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>

        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="container background_color_white">
                <main role="main" className="pb-3">
                  {children}
                </main>
              </div>
              <br />
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>

        <footer className="border-top footer text-muted">
          <div className="container">
            &copy; 2023 - OnlineToolSpot -{" "}
            <Link to="/privacy">Legal & Privacy</Link> -{" "}
            <Link to="/sitemap">Sitemap</Link> -{" "}
            <Link to="/contact">Contact</Link> - <Link to="/about">About</Link>
          </div>
        </footer>
      </div>
    );
  }
}

export default Layout;
