import React from "react";
import { useParams } from "react-router-dom";
import WeightConverter from "./WeightConverter";
import LengthConverter from "./LengthConverter";
import MetaSlugs from "../constants/meta_slugs.ts";
import TemperatureConverter from "./TemperatureConverter.jsx";
import MD5HashGenerator from "./MD5HashGenerator.jsx";
import WordCounter from "./WordCounter.jsx";
import LoremIpsumGenerator from "./LoremIpsumGenerator.jsx";

const ConverterRouter = () => {
  const { converterSlug } = useParams();

  // Conditionally render components based on the slug
  switch (converterSlug) {
    case MetaSlugs.CmToMileConverter:
    case MetaSlugs.MileToCmConverter:
    case MetaSlugs.CmToMeterConverter:
    case MetaSlugs.MeterToCmConverter:
    case MetaSlugs.CmToFeetConverter:
    case MetaSlugs.FeetToCmConverter:
    case MetaSlugs.CmToInchConverter:
    case MetaSlugs.InchToCmConverter:
    case MetaSlugs.CmToKmConverter:
    case MetaSlugs.KmToCmConverter:
    case MetaSlugs.CmToMmConverter:
    case MetaSlugs.MmToCmConverter:
    case MetaSlugs.CmToYardConverter:
    case MetaSlugs.YardToCmConverter:
    case MetaSlugs.MmToMeterConverter:
    case MetaSlugs.MmToKmConverter:
    case MetaSlugs.MmToInchConverter:
    case MetaSlugs.MmToFeetConverter:
    case MetaSlugs.MmToYardConverter:
    case MetaSlugs.MmToMileConverter:
    case MetaSlugs.MeterToMmConverter:
    case MetaSlugs.MeterToKmConverter:
    case MetaSlugs.MeterToInchConverter:
    case MetaSlugs.MeterToFeetConverter:
    case MetaSlugs.MeterToYardConverter:
    case MetaSlugs.MeterToMileConverter:
    case MetaSlugs.KmToMmConverter:
    case MetaSlugs.KmToMeterConverter:
    case MetaSlugs.KmToInchConverter:
    case MetaSlugs.KmToFeetConverter:
    case MetaSlugs.KmToYardConverter:
    case MetaSlugs.KmToMileConverter:
    case MetaSlugs.InchToMmConverter:
    case MetaSlugs.InchToMeterConverter:
    case MetaSlugs.InchToKmConverter:
    case MetaSlugs.InchToFeetConverter:
    case MetaSlugs.InchToYardConverter:
    case MetaSlugs.InchToMileConverter:
    case MetaSlugs.FeetToMmConverter:
    case MetaSlugs.FeetToMeterConverter:
    case MetaSlugs.FeetToKmConverter:
    case MetaSlugs.FeetToInchConverter:
    case MetaSlugs.FeetToYardConverter:
    case MetaSlugs.FeetToMileConverter:
    case MetaSlugs.YardToMmConverter:
    case MetaSlugs.YardToMeterConverter:
    case MetaSlugs.YardToKmConverter:
    case MetaSlugs.YardToInchConverter:
    case MetaSlugs.YardToFeetConverter:
    case MetaSlugs.YardToMileConverter:
    case MetaSlugs.MileToMmConverter:
    case MetaSlugs.MileToMeterConverter:
    case MetaSlugs.MileToKmConverter:
    case MetaSlugs.MileToInchConverter:
    case MetaSlugs.MileToFeetLengthConverter:
    case MetaSlugs.MileToYardLengthConverter:
      return <LengthConverter />;

    case MetaSlugs.MicrogramToMilligramConverter:
    case MetaSlugs.MicrogramToGramConverter:
    case MetaSlugs.MicrogramToKilogramConverter:
    case MetaSlugs.MicrogramToMetricTonConverter:
    case MetaSlugs.MicrogramToOunceConverter:
    case MetaSlugs.MicrogramToPoundConverter:
    case MetaSlugs.MicrogramToStoneConverter:
    case MetaSlugs.MilligramToMicrogramConverter:
    case MetaSlugs.MilligramToGramConverter:
    case MetaSlugs.MilligramToKilogramConverter:
    case MetaSlugs.MilligramToMetricTonConverter:
    case MetaSlugs.MilligramToOunceConverter:
    case MetaSlugs.MilligramToPoundConverter:
    case MetaSlugs.MilligramToStoneConverter:
    case MetaSlugs.GramToMicrogramConverter:
    case MetaSlugs.GramToMilligramConverter:
    case MetaSlugs.GramToKilogramConverter:
    case MetaSlugs.GramToMetricTonConverter:
    case MetaSlugs.GramToOunceConverter:
    case MetaSlugs.GramToPoundConverter:
    case MetaSlugs.GramToStoneConverter:
    case MetaSlugs.KilogramToMicrogramConverter:
    case MetaSlugs.KilogramToMilligramConverter:
    case MetaSlugs.KilogramToGramConverter:
    case MetaSlugs.KilogramToMetricTonConverter:
    case MetaSlugs.KilogramToOunceConverter:
    case MetaSlugs.KilogramToPoundConverter:
    case MetaSlugs.KilogramToStoneConverter:
    case MetaSlugs.MetricTonToMicrogramConverter:
    case MetaSlugs.MetricTonToMilligramConverter:
    case MetaSlugs.MetricTonToGramConverter:
    case MetaSlugs.MetricTonToKilogramConverter:
    case MetaSlugs.MetricTonToOunceConverter:
    case MetaSlugs.MetricTonToPoundConverter:
    case MetaSlugs.MetricTonToStoneConverter:
    case MetaSlugs.OunceToMicrogramConverter:
    case MetaSlugs.OunceToMilligramConverter:
    case MetaSlugs.OunceToGramConverter:
    case MetaSlugs.OunceToKilogramConverter:
    case MetaSlugs.OunceToMetricTonConverter:
    case MetaSlugs.OunceToPoundConverter:
    case MetaSlugs.OunceToStoneConverter:
    case MetaSlugs.PoundToMicrogramConverter:
    case MetaSlugs.PoundToMilligramConverter:
    case MetaSlugs.PoundToGramConverter:
    case MetaSlugs.PoundToKilogramConverter:
    case MetaSlugs.PoundToMetricTonConverter:
    case MetaSlugs.PoundToOunceConverter:
    case MetaSlugs.PoundToStoneConverter:
    case MetaSlugs.StoneToMicrogramConverter:
    case MetaSlugs.StoneToMilligramConverter:
    case MetaSlugs.StoneToGramConverter:
    case MetaSlugs.StoneToKilogramConverter:
    case MetaSlugs.StoneToMetricTonConverter:
    case MetaSlugs.StoneToOunceConverter:
    case MetaSlugs.StoneToPoundConverter:
      return <WeightConverter />;

    case MetaSlugs.FahrenheitToCelsiusConverter:
    case MetaSlugs.FahrenheitToKelvinConverter:
    case MetaSlugs.FahrenheitToRankineConverter:
    case MetaSlugs.CelsiusToFahrenheitConverter:
    case MetaSlugs.CelsiusToKelvinConverter:
    case MetaSlugs.CelsiusToRankineConverter:
    case MetaSlugs.KelvinToFahrenheitConverter:
    case MetaSlugs.KelvinToCelsiusConverter:
    case MetaSlugs.KelvinToRankineConverter:
    case MetaSlugs.RankineToFahrenheitConverter:
    case MetaSlugs.RankineToCelsiusConverter:
    case MetaSlugs.RankineToKelvinConverter:
      return <TemperatureConverter />;

    case MetaSlugs.Md5HashGenerator:
      return <MD5HashGenerator />;

    case MetaSlugs.WordCounter:
      return <WordCounter />;

    case MetaSlugs.LoremIpsumGenerator:
      return <LoremIpsumGenerator />;
  }
};

export default ConverterRouter;
